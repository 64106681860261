'use client';

import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { Link } from '@/components/Link';
import { useAuthData } from '@/hooks/useAuthData';
import { useRouter } from '@/hooks/useRouter';
import { useSupplier } from '@/hooks/useSupplier';
import { useTransparentHeader } from '@/hooks/useTransparentHeader';
import { StringBool } from '@/models/StringBool.enum';
import { usePathname } from '@/navigation';
import closeIcon from '@/public/icons/closeIcon.svg';
import {
  getAllParamsFromSearchParams,
  stringifyUrl,
} from '@/utils/stringify-url';

import { VerticalDivider } from '../VerticalDivider';
import GlobalNav from './GlobalNav';
import HamburgerIcon from './HamburgerIcon';
import LargeIndustriousLogo from './LargeIndustriousLogo';
import NuveenLogo from './NuveenLogo';
import SmallIndustriousLogo from './SmallIndustriousLogo';
import UserModal, { UserModals } from './UserModal';

import clsx from 'clsx';

const GlobalNavMobile = dynamic(() => import('./GlobalNavMobile'), {
  ssr: false,
});

type HeaderProps = {
  isNavigationTransparent?: boolean;
};

enum HeaderQueryActions {
  LOGIN = 'login',
}
export function Header({ isNavigationTransparent }: HeaderProps) {
  const [isMobileMenuOpened, setMobileMenuOpen] = useState(false);
  const { isTransparent, setIsHover } = useTransparentHeader(
    isNavigationTransparent
  );
  const [userModalType, setUserModalType] = useState<UserModals | undefined>();
  const [email, setEmail] = useState('');

  const t = useTranslations('nav');
  const router = useRouter();
  const searchParams = useSearchParams();
  const allSearchParams = getAllParamsFromSearchParams(searchParams);

  const pathname = usePathname();
  const { isNuveenSupplier } = useSupplier();
  const { isAuthenticated, isLoading: isLoadingAuthData } = useAuthData();

  useEffect(() => {
    if (searchParams?.get('action') === HeaderQueryActions.LOGIN) {
      setUserModalType(UserModals.LOGIN);
      setEmail(searchParams.get('email') as string);
    }
  }, [searchParams?.get('action')]);

  useEffect(() => {
    if (!isAuthenticated && isNuveenSupplier && !isLoadingAuthData) {
      setUserModalType(UserModals.SIGNUP);
    }
  }, [isAuthenticated, isNuveenSupplier, isLoadingAuthData]);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpened);
  };

  const handleUserItemMobileClick = (menu: UserModals) => {
    setMobileMenuOpen(false);
    setUserModalType(menu);
  };

  const handleOnSubMenuItemMobileClick = async (
    key: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    if (key === 'logout') {
      event.preventDefault();
      const { logout } = await import('@/lib/authentication/helpers');
      logout();
      router.replace('/');
      router.refresh();
      window.location.reload();
    }
    return;
  };

  const handleLoginSuccess = () => {
    setUserModalType(undefined);
    if (searchParams?.get('redirectUrl')) {
      return router.replace(`${searchParams?.get('redirectUrl')}`);
    }
    if (searchParams?.get('action') === HeaderQueryActions.LOGIN) {
      return replaceUrlWithoutActionAndRedirect();
    }
    router.refresh();
  };

  const closeUserModal = () => {
    setUserModalType(undefined);
    replaceUrlWithoutActionAndRedirect();
  };

  const replaceUrlWithoutActionAndRedirect = () => {
    // Remove redirectUrl and action query params but keep the rest
    const newSearchParams = {
      ...allSearchParams,
      action: undefined,
      redirectUrl: undefined,
      closable: undefined,
    };
    router.replace(stringifyUrl(pathname!, newSearchParams), undefined);
  };

  return (
    <>
      {userModalType != null && (
        <UserModal
          modal={userModalType}
          email={email}
          closable={
            searchParams?.get('closable') === StringBool.False ? false : true
          }
          onLoginSuccess={handleLoginSuccess}
          onClose={closeUserModal}
          onSignUpClick={() => setUserModalType(UserModals.SIGNUP)}
          onLoginClick={() => setUserModalType(UserModals.LOGIN)}
          onResetClick={() => setUserModalType(UserModals.RESET)}
        />
      )}
      <div
        className="sticky h-[48px] sm:h-[70px]  top-0 inset-x-0 z-10"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => !isMobileMenuOpened && setIsHover(false)}
      >
        <div
          className={clsx(
            'relative w-full h-full border-b',
            isTransparent && 'bg-transparent border-b-transparent',
            !isTransparent && 'bg-white border-b-grey-90'
          )}
        >
          {isNuveenSupplier ? (
            <div className="flex items-center justify-between h-full px-5 sm:pl-10">
              <div className="flex items-center h-full">
                <button
                  className="lg:hidden"
                  onClick={toggleMobileMenu}
                  aria-expanded={isMobileMenuOpened}
                >
                  {!isMobileMenuOpened && (
                    <HamburgerIcon
                      dataTestId="toggle-mobile-nav"
                      className={clsx(isTransparent && 'fill-white')}
                    />
                  )}
                  {isMobileMenuOpened && (
                    <Image
                      data-testid="toggle-mobile-nav"
                      src={closeIcon}
                      width={20}
                      height={20}
                      alt={t('close-icon-alt')}
                    />
                  )}
                </button>
                <VerticalDivider className="m-5 lg:hidden bg-grey-90 h-4/6" />
                <Link href="/" data-testid="supplier-nav-logo">
                  <NuveenLogo className={clsx(isTransparent && 'fill-white')} />
                </Link>
              </div>

              <GlobalNav
                isTransparentMode={isTransparent}
                openModal={setUserModalType}
                isNuveenSupplier
              />

              {isMobileMenuOpened && (
                <GlobalNavMobile
                  onSubMenuItemMobileClick={handleOnSubMenuItemMobileClick}
                  handleUserItemMobileClick={handleUserItemMobileClick}
                  isNuveenSupplier
                />
              )}
            </div>
          ) : (
            <div className="flex items-center justify-between h-full px-5 sm:px-10 xl:container">
              <div className="flex items-center h-full">
                <button
                  className="lg:hidden"
                  onClick={toggleMobileMenu}
                  aria-expanded={isMobileMenuOpened}
                >
                  {!isMobileMenuOpened && (
                    <HamburgerIcon
                      dataTestId="toggle-mobile-nav"
                      className={clsx(isTransparent && 'fill-white')}
                    />
                  )}
                  {isMobileMenuOpened && (
                    <Image
                      data-testid="toggle-mobile-nav"
                      src={closeIcon}
                      width={20}
                      height={20}
                      alt={t('close-icon-alt')}
                    />
                  )}
                </button>
                <VerticalDivider className="m-5 lg:hidden bg-grey-90 h-4/6" />
                <Link className="md:hidden" href="/" data-testid="nav-logo">
                  <SmallIndustriousLogo
                    className={clsx(isTransparent && 'fill-white')}
                  />
                </Link>
                <Link className="max-md:hidden" href="/" data-testid="nav-logo">
                  <LargeIndustriousLogo
                    className={clsx(isTransparent && 'fill-white')}
                  />
                </Link>
              </div>

              <GlobalNav
                isTransparentMode={isTransparent}
                openModal={setUserModalType}
              />

              {isMobileMenuOpened && (
                <GlobalNavMobile
                  onSubMenuItemMobileClick={handleOnSubMenuItemMobileClick}
                  handleUserItemMobileClick={handleUserItemMobileClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
